import logo from './logo.svg';
import './App.css';
import { Controls, Player } from '@lottiefiles/react-lottie-player';

function App() {
  return (
    <div className="App">
      <Player
        autoplay
        loop
        src="https://assets5.lottiefiles.com/packages/lf20_m6cuL6.json"
        style={{ height: '80vh', width: '100%' }}
      >
        <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
      </Player>
      <h2 style={{ color: "black", textTransform: "uppercase" }}>Powered By <a style={{ color: "red", textTransform: "uppercase" }} href="https://raishahost.com" target="_blank" rel="noopener noreferrer" >RaishaHost.com</a> </h2>

    </div>
  );
}

export default App;
